import { createMarkup } from '@finn/auto-ui/utils/html';
import { GenericVehicleDetails } from '@finn/ua-vehicle';
import { isMobileApp, replaceStrKeys } from '@finn/ui-utils';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useContext, useMemo } from 'react';

import config from '~/config';
import CheckoutContext from '~/contexts/Checkout';
import { SeoHeaderData } from '~/types/cosmicModules';

type Props = {
  seoHeader?: SeoHeaderData;
  canonicalUrl?: string;
  isNoIndexNoFollow?: boolean;
  tabTitle?: string;
  appDeepLink?: string;
  jsonLdContent?: string | string[];
};

export const useCurrentUrl = (locale: string, asPath: string) => {
  return useMemo(() => {
    const nonDefaultLocale = locale === 'default' ? 'de-DE' : locale;
    const pathWithoutQuery = asPath.split(/[?#]/)[0];
    const fixedRootPath = pathWithoutQuery === '/' ? '' : pathWithoutQuery;

    return `${config.FINN_WEB_URL}/${nonDefaultLocale}${fixedRootPath}`;
  }, [asPath, locale]);
};

export const getDescription = (
  seoHeader: SeoHeaderData,
  vehicle: GenericVehicleDetails
) => {
  let description = seoHeader?.metadata?.og_description || '';
  description = replaceStrKeys(description, {
    brand: vehicle?.brand?.id || '',
    model: vehicle?.model || '',
  });

  return description;
};

export const HeadTags: React.FunctionComponent<Props> = ({
  seoHeader,
  canonicalUrl,
  isNoIndexNoFollow,
  tabTitle,
  appDeepLink,
  jsonLdContent,
}) => {
  const { locale, asPath } = useRouter();

  const strippedPath = useMemo(() => {
    // Remove trailing slash
    const length = asPath.length;

    if (length > 0 && asPath.charAt(length - 1) === '/') {
      return asPath.substring(0, length - 1);
    }

    return asPath;
  }, [asPath]);

  const { vehicle } = useContext(CheckoutContext);
  const currentUrl = useCurrentUrl(locale, asPath);
  const description = getDescription(seoHeader, vehicle);
  const isMultiLocale = seoHeader?.metadata?.multi_locale || false;
  const pageTitle = tabTitle || seoHeader?.metadata?.title || '';
  const noIndexProperty = seoHeader?.metadata?.no_index;

  // temporary fix to check type of no_index from Cosmic because Cosmic sometimes returns string or boolean (apparent Cosmic bug)
  let isNoIndex = false;
  if (isNoIndexNoFollow === null || isNoIndexNoFollow === undefined) {
    if (typeof noIndexProperty === 'string') {
      isNoIndex = noIndexProperty === 'true';
    } else if (typeof noIndexProperty === 'boolean') {
      isNoIndex = noIndexProperty;
    }
  } else {
    isNoIndex = isNoIndexNoFollow;
  }

  const ldContentArr = Array.isArray(jsonLdContent)
    ? jsonLdContent
    : [jsonLdContent];

  return (
    <Head>
      <title>{pageTitle}</title>
      <link rel="canonical" href={canonicalUrl || currentUrl} />
      {isMultiLocale && (
        <>
          <link
            data-testid="alternate-de"
            rel="alternate"
            hrefLang="de-DE"
            href={`${config.FINN_WEB_URL}/de-DE${strippedPath}`}
          />
          <link
            data-testid="alternate-en"
            rel="alternate"
            hrefLang="en-US"
            href={`${config.FINN_WEB_URL}/en-US${strippedPath}`}
          />
        </>
      )}
      <meta
        data-testid="description"
        name="description"
        content={description}
      />
      <meta
        data-testid="meta-keywords"
        name="keywords"
        content={seoHeader?.metadata?.keywords || ''}
      />

      <meta
        data-testid="og-image"
        property="og:image"
        content={seoHeader?.metadata?.og_image?.url || ''}
      />
      <meta
        property="og:title"
        data-testid="meta-og_title"
        content={seoHeader?.metadata?.og_title || pageTitle}
      />
      <meta
        property="og:type"
        data-testid="meta-og_type"
        content={seoHeader?.metadata?.og_type || 'website'}
      />
      <meta
        property="og:url"
        data-testid="meta-og_url"
        content={seoHeader?.metadata?.og_url || currentUrl}
      />
      <meta
        property="og:description"
        data-testid="meta-og_description"
        content={seoHeader?.metadata?.og_description || ''}
      />
      <meta
        property="og:image:secure_url"
        data-testid="meta-og_image_secure_url"
        content={seoHeader?.metadata?.og_image?.url || ''}
      />
      <meta
        property="og:image:url"
        data-testid="meta-og_image_url"
        content={seoHeader?.metadata?.og_image?.url || ''}
      />
      <meta
        property="og:image:type"
        data-testid="meta-og_image_type"
        content={seoHeader?.metadata?.og_image_type || ''}
      />
      <meta
        property="og:image:width"
        data-testid="meta-og_image_width"
        content={seoHeader?.metadata?.og_image_width || ''}
      />
      <meta
        property="og:image:height"
        data-testid="meta-og_image_height"
        content={seoHeader?.metadata?.og_image_height || ''}
      />
      <meta
        property="og:site_name"
        data-testid="meta-og_site_name"
        content={seoHeader?.metadata?.og_site_name || ''}
      />
      <meta name="google-site-verification" content={config.GMC_TAG} />
      {isNoIndex && (
        <>
          <meta
            data-testid="meta-robots"
            name="robots"
            content="noindex,follow"
          />
          <meta
            data-testid="meta-googlebot"
            name="googlebot"
            content="noindex,follow"
          />
        </>
      )}
      {appDeepLink && !isMobileApp() ? (
        <script id="apps_flyer_smart_banner">
          {`!function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){(t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","banners",{banners: {key: "${config.APPS_FLYER_SMART_BANNER_KEY}"}});
        AF('banners', 'showBanner', { additionalParams: { af_dp: '${appDeepLink}', deep_link_value: '${appDeepLink}' }}); `}
        </script>
      ) : null}
      {jsonLdContent &&
        ldContentArr.map((ld, index) => (
          <script
            data-testid="json-ld"
            key={index}
            type="application/ld+json"
            dangerouslySetInnerHTML={createMarkup(ld)}
          />
        ))}
    </Head>
  );
};

export default HeadTags;
