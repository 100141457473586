import { Logo } from '@finn/design-system/icons/logo';
import { NextLinkSingleApp } from '@finn/ui-components';
import { App, cn } from '@finn/ui-utils';
import React, { useContext } from 'react';

import CheckoutContext from '~/contexts/Checkout';

export const TermsRowV2: React.FC = () => {
  const { pageData } = useContext(CheckoutContext);

  const footerItemsClasses = 'm-0 pr-2 body-12-light text-white sm:pr-4';

  return (
    <div className="flex w-full flex-col gap-4 md:flex-row">
      <div className="flex grow flex-row items-center gap-4">
        <Logo width={43} height={15} className="fill-white" />
        <div className="ml-auto flex flex-wrap justify-end">
          <NextLinkSingleApp
            app={App.UA}
            href={pageData?.metadata?.footer?.metadata?.terms?.metadata?.href}
            className={footerItemsClasses}
          >
            {pageData?.metadata?.footer?.metadata?.terms?.metadata?.label}
          </NextLinkSingleApp>
          <NextLinkSingleApp
            app={App.UA}
            href={pageData?.metadata?.footer?.metadata?.imprint?.metadata?.href}
            className={footerItemsClasses}
          >
            {pageData?.metadata?.footer?.metadata?.imprint?.metadata?.label}
          </NextLinkSingleApp>
          <NextLinkSingleApp
            app={App.UA}
            href={pageData?.metadata?.footer?.metadata?.privacy?.metadata?.href}
            className={footerItemsClasses}
          >
            {pageData?.metadata?.footer?.metadata?.privacy?.metadata?.label}
          </NextLinkSingleApp>
        </div>
      </div>
      <p className={cn(footerItemsClasses, 'self-center')}>
        {pageData?.metadata?.footer?.metadata?.copyright}
      </p>
    </div>
  );
};
