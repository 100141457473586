import { Badge } from '@finn/design-system/atoms/badge';
import { Features, useIsCVariant } from '@finn/ua-featureflags';
// Disabling because this component will be migrated as part of Toggle component in DS
// eslint-disable-next-line no-restricted-imports
import { ButtonBase, makeStyles, Theme } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';

// TODO design-system: refactor !important styles after migration to new typography APP-1443

const useStyles = makeStyles((theme: Theme) => ({
  cardWrapper: {
    width: '100%',
    padding: theme.spacing(1, 0),
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '2px',
    cursor: 'pointer',
    minHeight: 64,
    '& p': {
      fontWeight: 300,
    },
  },
  disabled: {
    opacity: 0.4,
  },
  label: {
    borderRadius: 2,
    padding: 4,
    fontSize: 11,
    fontWeight: 500,
    marginLeft: theme.spacing(1),
  },
}));

interface IProps {
  title: string | number;
  disabled?: boolean;
  label?: string;
  desc?: string | number;
  descComponent?: JSX.Element;
  isSelected: boolean;
  id?: string;
  onClick?: () => void;
  downpaymentDiscount?: boolean;
}

export const ProductOptionCard: React.FunctionComponent<IProps> = ({
  title,
  disabled,
  desc: descount,
  descComponent: discountComponent,
  isSelected,
  id,
  onClick,
  downpaymentDiscount,
}) => {
  const classes = useStyles();
  const i18n = useIntl();
  const isCVariant = useIsCVariant(Features.ExpDownPaymentOptions);

  return (
    <ButtonBase
      id={id}
      onClick={onClick}
      disabled={disabled}
      className={cn(classes.cardWrapper, '!border !border-solid', {
        '!shadow-inset1 !border-black': isSelected,
        '!border-pearl hover:!border-iron hover:!shadow-inset1Iron focus-within:border-iron focus-within:!shadow-inset1Iron':
          !isSelected,
        [classes.disabled]: disabled,
      })}
    >
      <div className="flex flex-col gap-1">
        <div
          className={`mb-1 flex gap-2 ${isCVariant ? 'flex-col' : 'flex-row'}`}
        >
          <span className="global-t6-semibold">{title}</span>
          {downpaymentDiscount && !isCVariant && (
            <Badge
              variant="monochromeBlue"
              withStroke
              className="body-12-medium -mt-1 h-[19px]"
            >
              {i18n.formatMessage({
                id: 'pdp.downPayment.optionDescriptionWith',
              })}
            </Badge>
          )}
        </div>
        <div className="body-14-regular">
          {discountComponent && discountComponent}
        </div>

        <div className="body-14-regular">{descount}</div>
      </div>
    </ButtonBase>
  );
};
