import { SkeletonNode } from '@finn/atoms';
import { cn, getTermLabel } from '@finn/ui-utils';
import React from 'react';
import { useIntl } from 'react-intl';

import FormattedPrice from '~/components/Format/FormattedPrice';
import useFormattedNumber from '~/hooks/useFormattedNumber';
import { useGetPreviousCart } from '~/modules/checkout/hooks/useGetPreviousCart';
import { useGetPreviousDeal } from '~/modules/checkout/hooks/useGetPreviousDeal';

import { useModalStyles } from './modalStyles';

const InfoRow: React.FC<{ label: string; value: string | JSX.Element }> = ({
  label,
  value,
}) => {
  return (
    <>
      <span className="body-14-light">{label}</span>
      <span className="body-14-semibold">{value}</span>
    </>
  );
};

export const AttributesBlock: React.FC = () => {
  const classes = useModalStyles();
  const { data: dealData, isLoading } = useGetPreviousDeal();
  const previousCart = useGetPreviousCart();
  const data = dealData || previousCart;
  const i18n = useIntl();

  const monthlyTotalLabel = i18n.formatMessage({
    id: 'checkout.monthlyTotalLabel',
  });
  const contractDurationLabel = i18n.formatMessage({
    id: 'checkout.contractDuration',
  });
  const flexTermLabel = i18n.formatMessage({ id: 'checkout.flexTermLabel' });
  const kmIncludedLabel = i18n.formatMessage({ id: 'checkout.kmIncluded' });
  const kmPackage = useFormattedNumber(data?.kmPackage || 1000);
  const termInfo = getTermLabel(Number(data?.term || 6), flexTermLabel, i18n);
  const kmPackageValue = i18n.formatMessage(
    {
      id: 'checkout.kmPackageForMonth',
    },
    {
      kmPackage,
    }
  );

  const attributes = [
    { label: contractDurationLabel, value: termInfo },
    { label: kmIncludedLabel, value: kmPackageValue },
    {
      label: monthlyTotalLabel,
      value: (
        <SkeletonNode loading={isLoading}>
          <FormattedPrice value={data?.amount || 0} maximumFractionDigits={0} />
        </SkeletonNode>
      ),
    },
  ];

  return (
    <div className={cn(classes.attributesBlock, 'gap-2')}>
      {attributes.map(({ label, value }) => (
        <InfoRow key={label} label={label} value={value} />
      ))}
    </div>
  );
};
