import { makeStyles, Theme } from '@material-ui/core';

export const useModalStyles = makeStyles((theme: Theme) => ({
  textBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  ctaBlock: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
  },
  infoRow: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  infoIcon: {
    display: 'block',
    flexBasis: 32,
    flexShrink: 0,
  },
  carTitleAndImage: {
    display: 'flex',
  },
  carTitleBlock: {
    flex: '0 0 40%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      flex: '0 0 50%',
      gap: theme.spacing(3),
    },
  },
  carImageBlock: {
    flex: '0 0 60%',
    '& img': {
      maxWidth: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      flex: '0 0 50%',
    },
  },
  attributesBlock: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
}));
