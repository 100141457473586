import { Logo } from '@finn/design-system/icons/logo';
import { MinimisedFooterData } from '@finn/ui-cosmic';
import { makeStyles, Theme } from '@material-ui/core';
import Link from 'next/link';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  footerLinks: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(4),
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      order: 2,
      gap: theme.spacing(6),
      alignItems: 'center',
    },
  },
}));

type MinimisedFooterProps = {
  data: MinimisedFooterData;
};

const MinimisedFooter = ({ data }: MinimisedFooterProps) => {
  const classes = useStyles();
  const i18n = useIntl();

  return (
    <footer className="bg-black pb-8 pt-12 text-white">
      <div className="container">
        <div className="flex-wrap items-center md:flex md:justify-between">
          <div className={classes.footerLinks}>
            {data?.metadata?.privacy?.map((item) => (
              <Link
                key={item?.slug}
                href={item?.metadata?.href}
                target="_blank"
              >
                <a className="body-14-regular md:body-16-regular text-white">
                  {item?.metadata?.label}
                </a>
              </Link>
            ))}
          </div>
          <div>
            <Logo className="fill-white" />
            <p className="body-12-regular mt-4">
              {i18n.formatMessage({ id: 'general.copyright' })}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default MinimisedFooter;
