import { Button } from '@finn/design-system/atoms/button';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { config, Locale, useCurrentLocale, useIsMobile } from '@finn/ui-utils';
import { NextRouter, useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { useGetPreviousCart } from '~/modules/checkout/hooks/useGetPreviousCart';
import { useGetPreviousDeal } from '~/modules/checkout/hooks/useGetPreviousDeal';
import { PreviousCartInfo } from '~/types/checkout';
import { getCheckoutCartUrl } from '~/utils/checkout';

import { useModalStyles } from './modalStyles';

type Props = {
  onClose(): void;
};

const navigateToCartOrPDP = (
  previousCart: PreviousCartInfo | null,
  router: NextRouter,
  locale: string,
  onClose: () => void
) => {
  if (
    !previousCart?.vehicleId ||
    !previousCart?.term ||
    !previousCart?.kmPackage
  ) {
    console.error('Invalid cart data');

    return;
  }

  if (previousCart?.isCart) {
    router
      .push(
        getCheckoutCartUrl(
          previousCart.vehicleId,
          previousCart.term,
          previousCart.kmPackage
        )
      )
      .finally(onClose);
  } else if (previousCart?.isConfigure) {
    router
      .push(`/${locale}/configure/${previousCart.vehicleId}`)
      .finally(onClose);
  } else {
    router.push(`/${locale}/pdp/${previousCart.vehicleId}`).finally(onClose);
  }
};

export const ContinueButton: React.FC<Props> = ({ onClose }) => {
  const [loading, setLoading] = useState(false);
  const classes = useModalStyles();
  const i18n = useIntl();
  const router = useRouter();
  const { locale } = useCurrentLocale();
  const { data } = useGetPreviousDeal();
  const previousCart = useGetPreviousCart();
  const track = useTrackingStore((state) => state.track);

  const { checkoutStep, contactId, dealId, checkoutHash } = data || {};
  const isMobile = useIsMobile();
  console.log({ locale });

  const onModalClose = useCallback(() => {
    setLoading(false);
    onClose();
  }, [onClose]);

  const onCTAClick = useCallback(() => {
    setLoading(true);
    track(TrackingEventName.CTA_CLICKED, {
      location: 'basket',
      additionalProps: {
        returnTo: contactId
          ? 'checkout'
          : previousCart?.isCart
            ? 'cart'
            : previousCart?.isConfigure
              ? 'configure'
              : 'pdp',
      },
    });

    if (contactId) {
      if (locale === Locale.ENGLISH_GERMANY) {
        router.push(
          `${config.FINN_WEB_URL}/${Locale.GERMAN_GERMANY}/checkout/${checkoutStep}/${contactId}/${dealId}/${checkoutHash}`
        );
      }

      router
        .push(
          `/${locale}/checkout/${checkoutStep}/${contactId}/${dealId}/${checkoutHash}`
        )
        .finally(onModalClose);
    } else {
      navigateToCartOrPDP(previousCart, router, locale, onModalClose);
    }
  }, [
    checkoutHash,
    checkoutStep,
    contactId,
    dealId,
    locale,
    onModalClose,
    previousCart,
    router,
  ]);

  return (
    <div className={classes.ctaBlock}>
      <Button
        data-cy="empty-cart-cta"
        className={isMobile ? 'w-full' : ''}
        onClick={onCTAClick}
        loading={loading}
      >
        {i18n.formatMessage({
          id: 'checkout.pageData.next_step_button',
        })}
      </Button>
    </div>
  );
};
