import { PreviousDealInfo } from '@finn/auto-ui/types/checkout';
import { CookieKeys, getClientCookie, useSession } from '@finn/ui-utils';
import { useEffect, useState } from 'react';
import useSWR from 'swr';

import { previousDealFetcher } from '~/modules/checkout/api/previousDealFetcher';
import { PREVIOUS_DEAL_DEDUPING_INTERVAL } from '~/modules/checkout/constants';

export const useGetPreviousDeal = () => {
  const [session] = useSession();
  const [closedDealId, setClosedDealId] = useState<string>();

  // using state instead of reading the cookie directly as useGetPreviousDeal can be called from SSR so populating via useEffect
  const [lastDealIdFromCookie, setLastDealIdFromCookie] = useState<string>();

  const { data, isLoading, error } = useSWR<PreviousDealInfo | null>(
    session && lastDealIdFromCookie ? { lastDealIdFromCookie } : null,
    previousDealFetcher,
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: PREVIOUS_DEAL_DEDUPING_INTERVAL,
    }
  );

  if (error) {
    console.error({ message: error, category: 'SWR Previous Deal Api Call' });
  }

  // when a new deal is created, we put the deal id in a cookie, Based on that we want to invalidate the swr cache
  // this hooks sets the value of a state that's included in the swr key
  useEffect(() => {
    const lastDealIdInCookie = getClientCookie(CookieKeys.LAST_DEAL_ID);
    if (lastDealIdInCookie && lastDealIdInCookie !== lastDealIdFromCookie) {
      setLastDealIdFromCookie(lastDealIdInCookie);
    } else if (!lastDealIdFromCookie) {
      setLastDealIdFromCookie('none');
    }
  }, [lastDealIdFromCookie]);

  useEffect(() => {
    const dealId = getClientCookie(CookieKeys.CLOSED_DEAL_ID);
    if (dealId && dealId !== closedDealId) {
      setClosedDealId(dealId);
    }
  }, [closedDealId]);

  const shouldShowDeal =
    data?.dealId &&
    data?.brand &&
    data?.model &&
    data?.term &&
    data?.amount &&
    data?.checkoutStep &&
    String(data?.dealId) !== closedDealId;

  return { data: shouldShowDeal ? data : null, isLoading };
};
