import { useIntl } from 'react-intl';

import { constructFormattedNumberResponse } from '../types/localization';

const useFormattedNumber = (
  value: number,
  maximumFractionDigits: number = 2,
  forceDigits?: boolean
) => {
  const intl = useIntl();

  return constructFormattedNumberResponse(
    intl,
    value,
    maximumFractionDigits,
    forceDigits
  );
};

export default useFormattedNumber;
