import { traceabilityHeadersBrowser } from '@finn/ui-utils';
import axios from 'axios';

import { PreviousDealInfo } from '~/types/checkout';

export const previousDealFetcher =
  async (): Promise<PreviousDealInfo | null> => {
    try {
      const response = await axios.post(
        '/api/getPreviousDeal',
        {
          isUSA: false,
        },
        { headers: traceabilityHeadersBrowser() }
      );

      return response.data;
    } catch (error) {
      console.error({
        message: error,
        category: 'Axios Previous Deal Api Call',
      });

      return null;
    }
  };
