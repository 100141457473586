import { SpacingWrapper } from '@finn/platform-modules';
import { B2BMarketingHeader, Header } from '@finn/ua-header';
import { ModalContainer, ModalKey } from '@finn/ua-modals';
import { ErrorBoundary } from '@finn/ui-components';
import { BasePageData, MinimisedFooterData } from '@finn/ui-cosmic';
import { isMobileApp } from '@finn/ui-utils';
import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';

import Footer from '~/components/Footer';
import CheckoutFooter from '~/components/Footer/CheckoutFooter';
import { CartButton } from '~/modules/checkout/components/CartButton/CartButton';
import { LoginModal } from '~/modules/login/LoginModal';

import MinimisedFooter from '../components/Footer/MinimisedFooter';

interface IProps {
  data: BasePageData;
  checkoutFooter?: boolean;
  hideHeaderFooter?: boolean;
}
const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'relative',
    minHeight: '100vh',
  },
  footerSpace: {
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(8),
    },
  },
  hidden: {
    display: 'none',
  },
}));

const DefaultLayout: React.FunctionComponent<IProps> = ({
  children,
  data,
  checkoutFooter,
  hideHeaderFooter,
}) => {
  const classes = useStyles();
  const headerData = data.metadata.header;
  const footerData = data.metadata.footer;

  if (isMobileApp() || hideHeaderFooter) {
    return (
      <div className={classes.wrapper}>
        {/*
          we want to mount invisible header, as we want to have
          some modals available that rendered inside header
        */}
        <div className={classes.hidden}>
          <ErrorBoundary>
            <Header data={headerData} CartButton={CartButton} />
          </ErrorBoundary>
        </div>
        <ModalContainer modalKey={ModalKey.LOGIN} ModalComponent={LoginModal} />
        {children}
      </div>
    );
  }

  const isB2BPageHeader = headerData?.slug?.includes?.('b2b');
  const isB2BPageFooter = footerData?.slug?.includes?.('b2b');

  return (
    <div className={classes.wrapper}>
      <ErrorBoundary>
        {isB2BPageHeader ? (
          <B2BMarketingHeader data={headerData} />
        ) : (
          <Header data={headerData} CartButton={CartButton} />
        )}
      </ErrorBoundary>
      <ModalContainer modalKey={ModalKey.LOGIN} ModalComponent={LoginModal} />
      {children}
      {checkoutFooter && (
        <ErrorBoundary>
          <div className={classes.footerSpace} />
          <CheckoutFooter />
        </ErrorBoundary>
      )}
      {isB2BPageFooter && (
        <ErrorBoundary>
          <SpacingWrapper>
            <MinimisedFooter
              data={footerData as unknown as MinimisedFooterData}
            />
          </SpacingWrapper>
        </ErrorBoundary>
      )}
      {!checkoutFooter && !isB2BPageFooter && (
        <ErrorBoundary>
          <Footer data={footerData} />
        </ErrorBoundary>
      )}
    </div>
  );
};

export default DefaultLayout;
