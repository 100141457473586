import {
  formatNumberParts,
  getCorrectLocaleString,
  LOCALE_CURRENCIES,
} from '@finn/ui-utils';
import { useRouter } from 'next/router';
import React from 'react';
import { FormattedNumberParts } from 'react-intl';

interface IProps {
  value: number;
  maximumFractionDigits?: number;
  forceDigits?: boolean;
}

const FormattedPrice: React.FunctionComponent<IProps> = ({
  value,
  maximumFractionDigits = 2,
  forceDigits,
}) => {
  const { locale } = useRouter();
  const parsedLocale = getCorrectLocaleString(locale);
  const currency = LOCALE_CURRENCIES[parsedLocale];

  const roundedValue = maximumFractionDigits ? value : Math.ceil(value);

  return (
    <FormattedNumberParts
      value={roundedValue}
      style="currency"
      currency={currency}
      minimumFractionDigits={forceDigits ? maximumFractionDigits : 0}
      maximumFractionDigits={maximumFractionDigits}
    >
      {(parts) => <>{formatNumberParts(parts)}</>}
    </FormattedNumberParts>
  );
};

export default FormattedPrice;
