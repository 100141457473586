import {
  clearClientCookie,
  CookieKeys,
  getClientJSONCookie,
  useCurrentLocale,
} from '@finn/ui-utils';
import { useEffect, useState } from 'react';

import { PreviousCartInfo } from '~/types/checkout';

export const useGetPreviousCart = (): PreviousCartInfo | null => {
  const [previousCart, setPreviousCart] = useState<null | PreviousCartInfo>(
    null
  );
  const { locale } = useCurrentLocale();
  useEffect(() => {
    const savedCart = getClientJSONCookie(
      CookieKeys.RETURN_TO_CKO
    ) as PreviousCartInfo;
    if (
      !savedCart?.kmPackage ||
      !savedCart?.term ||
      !savedCart?.vehicleId ||
      savedCart?.locale !== locale
    ) {
      if (savedCart && savedCart?.locale !== locale) {
        clearClientCookie(CookieKeys.RETURN_TO_CKO);
      }

      return;
    }
    setPreviousCart(savedCart);
  }, [locale]);

  return previousCart;
};
