import { supportNavbarClicked } from '@finn/ua-tracking';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { get } from 'lodash';
import { useContext } from 'react';
import { useIntl } from 'react-intl';

import CheckoutContext from '~/contexts/Checkout';
import {
  TrustPilotRating,
  TrustpilotStyle,
} from '~/ui-elements/TrustPilotRating';

const useStyles = makeStyles((theme: Theme) => ({
  helpWrapper: {
    padding: theme.spacing(3, 0, 0),
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 0),
      flexDirection: 'row',
    },
  },
  trustPilotWrapper: {
    textAlign: 'left',
    alignSelf: 'center',
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  trustpilot: {
    left: -40,
  },
  phoneWrapper: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));

export const CheckoutFooterTopSection = () => {
  const i18n = useIntl();
  const { pageData } = useContext(CheckoutContext);
  const classes = useStyles();
  const needHelpText = i18n.formatMessage({ id: 'checkout.needHelp' });
  const operatingTime = i18n.formatMessage({ id: 'checkout.operatingTime' });
  const phoneNumber = get(
    pageData,
    'metadata.header.metadata.need_help.phone_number',
    []
  );

  return (
    <div className="border-pearl border border-solid bg-white">
      <div className="container">
        <Grid container>
          <Grid item xs={12} md={9} className={classes.helpWrapper}>
            <p className="body-16-semibold">{needHelpText}</p>
            <a
              href={get(phoneNumber, 'metadata.href')}
              onMouseDown={() => {
                supportNavbarClicked();
              }}
              className={classes.phoneWrapper}
            >
              <p className="body-14-semibold">
                {get(phoneNumber, 'metadata.label')}
              </p>
            </a>
            <p className="body-14-light">{operatingTime}</p>
          </Grid>
          <Grid item xs={12} md={3} className={classes.trustPilotWrapper}>
            <TrustPilotRating
              style={TrustpilotStyle.Checkout}
              mobileClassName={classes.trustpilot}
              mobileWidth="250px"
              width="250px"
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
