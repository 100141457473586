import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalTitle,
} from '@finn/design-system/atoms/modal';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { Image } from '@finn/ui-components';
import { getCloudinaryImgUrl, getCloudinarySrcSet } from '@finn/ui-utils';
import { Hidden } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useGetPreviousCart } from '~/modules/checkout/hooks/useGetPreviousCart';
import { useGetPreviousDeal } from '~/modules/checkout/hooks/useGetPreviousDeal';

import { AttributesBlock } from './AttributesBlock';
import { ContinueButton } from './ContinueButton';
import { useModalStyles } from './modalStyles';

type Props = {
  open: boolean;
  onClose(): void;
};

export const ReturnToCheckoutModal: React.FC<Props> = ({ open, onClose }) => {
  const i18n = useIntl();
  const classes = useModalStyles();
  const { data: dealData } = useGetPreviousDeal();
  const previousCart = useGetPreviousCart();
  const track = useTrackingStore((state) => state.track);
  const data = dealData || previousCart;

  const title = i18n.formatMessage({
    id: 'checkout.returnToCheckout.title',
  });
  const stillInterested = i18n.formatMessage({
    id: 'checkout.returnToCheckout.stillIntersted',
  });

  const cloudinaryURL = getCloudinaryImgUrl(data?.vehicleImage || '', {
    w: 400,
    dpr: 2,
  });
  const srcSet = getCloudinarySrcSet(data?.vehicleImage || '', [400]);

  useEffect(() => {
    track(TrackingEventName.BASKET_VIEWED, {
      location: 'full-basket',
    });
  }, []);

  return (
    <Modal open={open} onOpenChange={(isOpen) => !isOpen && onClose()}>
      <ModalContent>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
        </ModalHeader>
        <div className="bg-snow p-6">
          <div className={classes.carTitleAndImage}>
            <div className={classes.carTitleBlock}>
              <span className="body-14-semibold">{stillInterested}</span>
              <h5 className="global-t5-semibold">
                {data?.brand} {data?.model}
              </h5>
              <Hidden xsDown implementation="js">
                <AttributesBlock />
                <ContinueButton onClose={onClose} />
              </Hidden>
            </div>
            <div className={classes.carImageBlock}>
              <Image
                src={cloudinaryURL}
                srcSet={srcSet}
                alt={`${data?.brand} ${data?.model}`}
              />
            </div>
          </div>
          <Hidden smUp implementation="js">
            <AttributesBlock />
            <ContinueButton onClose={onClose} />
          </Hidden>
        </div>
      </ModalContent>
    </Modal>
  );
};
